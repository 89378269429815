/*                */
import { submitEvent } from "@otto-ec/tracking-bct";

/**
 *
 *
 *
 *
 *
 */
window.o_global.pali.Tooltip = function (trigger) {
  this.trigger = trigger; /*                                */
  this.tooltip = undefined; /*                     */

  this.tooltipX = 0; /*                                                        */
  this.tooltipY = 0; /*                                                        */
  this.baseClass = "p_tooltip"; /*                                    */
  this.mouseOffset = 0; /*                                                                     */

  this.throttleTime = 16.6; /*                                                                             */
  this.isActive = true; /*                                                                      */
  this.tooltipIsVisible = false; /*                                                               */
  this.avoidCloseTooltip = false; /*                                                                                  */
  this.type = ""; /*                                            */

  this.orientation = 1; /*                                                            */

  /*                                                                                               */
  this.orientationVariations = {
    1: "p_tooltipHorizontal",
    2: "p_tooltipHorizontal p_tooltipHorizontalTop",
    3: "p_tooltipHorizontal p_tooltipHorizontalRight",
    4: "p_tooltipHorizontal p_tooltipHorizontalTop p_tooltipHorizontalRight",
  };

  /**
 *
 *
 */
  this.dataSettings = {
    text: "data-tooltip", /*                                             */
    reference: "data-tooltip-ref", /*                                                                                */
    referenceToClone: "data-tooltip-ref-clone", /*                                                                                        */
    additionalStyleClasses: "data-tooltip-class", /*                                         */
    additionalContainerClasses: "data-tooltip-container-class", /*                                             */
    position: "data-tooltip-pos", /*                                                  */
    showForTouch: "data-tooltip-touch", /*                                                                       */
    hasCloseButton: "data-tooltip-close", /*                                                                    */
    triggerClick: "data-tooltip-trigger-click", /*                                                                            */
    type: "data-tooltip-type", /*                                                             */
    trackingObject: "data-trackingobject", /*                                          */
  };

  /*                                                          */
  this.text = "";
  this.additionalStyleClasses = "";
  this.additionalContainerClasses = "";
  this.reference = undefined;
  this.referenceToClone = false;
  this.position = "";
  this.showForTouch = true;
  this.triggerClick = false;
  this.hasCloseButton = true;
  this.trackingObject = undefined;

  this._init();
};

/**
 *
 *
 *
 *
 */
window.o_global.pali.Tooltip.prototype.getShowForTouch = function () {
  return this.showForTouch;
};

/**
 *
 *
 *
 *
 */
window.o_global.pali.Tooltip.prototype.hideTooltip = function () {
  if (this.tooltipIsVisible && !this.avoidCloseTooltip) {
    if (window.o_global.device.isTouchable || this.triggerClick) {
      if ("onpointerdown" in window && !("ontouchstart" in window)) {
        document.removeEventListener(
          "pointerdown",
          window.o_global.pali.tooltipHandler.blurTooltip,
        );
      } else {
        document.body.removeEventListener(
          "touchstart",
          window.o_global.pali.tooltipHandler.blurTooltip,
        );
      }
    }

    document.body.removeChild(this.tooltip);
    this.tooltipIsVisible = false;
  }
};

/**
 *
 *
 *
 *
 */
window.o_global.pali.Tooltip.prototype.moveTooltip = function (e) {
  /*                                                */
  if (!e.pageX && typeof e.clientX === "unknown") {
    /*                                                                 */
    return false;
  }

  const xPos = e.pageX || e.clientX + document.documentElement.scrollLeft;
  const yPos = e.pageY || e.clientY + document.documentElement.scrollTop;

  this.calculateTooltipPosition(xPos, yPos, true);
};

/**
 *
 *
 *
 *
 */
window.o_global.pali.Tooltip.prototype.showTooltip = function () {
  if (this.isActive) {
    if (
      !window.o_global.device.isTouchable || /*                        */
      this.triggerClick || /*                                                               */
      this.showForTouch || /*                                                       */
      window.o_global.device.hybrid.isDetected
    ) {
      /*                                                             */
      window.o_global.pali.tooltipHandler.changeActiveTooltip(this);

      if (!this.tooltip) {
        this._createTooltip();
        document.body.appendChild(this.tooltip);
        this.calculateTooltipPosition();
      } else {
        document.body.appendChild(this.tooltip);
        this.calculateTooltipPosition(); /*                                                      */
      }

      this.tooltipIsVisible = true;

      if (window.o_global.device.isTouchable || this.triggerClick) {
        /*                                                                      */
        if ("onpointerdown" in window && !("ontouchstart" in window)) {
          /*                       */
          document.addEventListener("pointerdown", window.o_global.pali.tooltipHandler.blurTooltip);
        } else if ("ontouchstart" in window) {
          document.body.addEventListener(
            "touchstart",
            window.o_global.pali.tooltipHandler.blurTooltip,
          );
        } else {
          document.body.addEventListener("click", window.o_global.pali.tooltipHandler.blurTooltip);
        }
      }

      if (this.trackingObject) {
        try {
          submitEvent(JSON.parse(this.trackingObject));
        } catch {
          /*  */
        }
      }
    }
  }
};

/**
 *
 *
 *
 */
window.o_global.pali.Tooltip.prototype.toggleTooltip = function (e) {
  if (this.isActive) {
    window.o_util.event.stop(e);

    if (this.tooltipIsVisible) {
      this.avoidCloseTooltip = false;
      this.hideTooltip();
    } else {
      this.avoidCloseTooltip = true;
      this.showTooltip();
    }
  }
};

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
window.o_global.pali.Tooltip.prototype.calculateTooltipPosition = function (left, top, isMouse) {
  const arrowBorderDistance = 19;
  let triggerTop;
  let triggerLeft;
  let box = {
    top: 0,
    left: 0,
  };
  const triggerWidth = isMouse ? 0 : this.trigger.offsetWidth;
  let triggerHeight = isMouse ? 0 : this.trigger.offsetHeight;
  const halfTriggerWidth = Math.floor(triggerWidth / 2);
  const tooltipWidth = this.tooltip.offsetWidth;
  const tooltipHeight = this.tooltip.offsetHeight;
  const scrollLeft = window.scrollX;
  const scrollTop = window.scrollY;
  let offset = this.mouseOffset || 12;
  let calculatedOrientation;
  let calculatedTop;
  let calculatedLeft;

  /*                                              */
  if (typeof this.trigger.getBoundingClientRect !== "undefined") {
    box = this.trigger.getBoundingClientRect();
  }

  triggerTop =
    top ||
    box.top +
      (window.pageYOffset || document.documentElement.scrollTop) -
      (document.documentElement.clientTop || 0);
  triggerLeft =
    left ||
    box.left +
      (window.pageXOffset || document.documentElement.scrollLeft) -
      (document.documentElement.clientLeft || 0);

  calculatedOrientation = 1;
  calculatedLeft = triggerLeft + halfTriggerWidth - arrowBorderDistance;
  if (
    window.innerWidth + scrollLeft < calculatedLeft + tooltipWidth &&
    scrollLeft < triggerLeft + halfTriggerWidth - tooltipWidth + arrowBorderDistance
  ) {
    calculatedOrientation = 3;
    calculatedLeft = triggerLeft + halfTriggerWidth - tooltipWidth + arrowBorderDistance;
  }

  calculatedTop = triggerTop - tooltipHeight - offset;
  if (
    scrollTop > calculatedTop &&
    scrollTop + window.innerHeight > triggerTop + offset + tooltipHeight
  ) {
    if (calculatedOrientation === 3) {
      calculatedOrientation = 4;
    } else {
      calculatedOrientation = 2;

      /*                                                                     */
      /*                                                                         */
      if (this.tooltipY !== 0 || this.tooltipX !== 0) {
        triggerHeight = 0;
        offset = 0;
      }
    }

    calculatedTop = triggerTop + triggerHeight + offset;
  }

  this.tooltip.style.left = this._getPixel(calculatedLeft + this.tooltipX);
  this.tooltip.style.top = this._getPixel(calculatedTop + this.tooltipY);

  if (calculatedOrientation !== this.orientation) {
    this.removeTooltipStyleClass(this.tooltip, this.orientationVariations[this.orientation]);
    this.orientation = calculatedOrientation;
    this.addTooltipStyleClass(this.tooltip, this.orientationVariations[this.orientation]);
  }
};

/**
 *
 *
 *
 *
 */
window.o_global.pali.Tooltip.prototype.addTooltipStyleClass = function (scope, styleClass) {
  scope.classList.add.apply(scope.classList, styleClass.split(" "));
};

/**
 *
 *
 *
 *
 */
window.o_global.pali.Tooltip.prototype.removeTooltipStyleClass = function (scope, styleClass) {
  scope.classList.remove.apply(scope.classList, styleClass.split(" "));
};

/**
 *
 */
window.o_global.pali.Tooltip.prototype.activateTooltip = function () {
  const triggerClass = "js_hasPaliTooltip";

  this.isActive = true;
  this.removeTooltipStyleClass(this.trigger, triggerClass);
  this.addTooltipStyleClass(this.trigger, `${triggerClass}--done`);

  this.showTooltip();
};

/**
 *
 */
window.o_global.pali.Tooltip.prototype.deactivateTooltip = function () {
  this.isActive = false;
  this.hideTooltip();
};

/**
 *
 *
 *
 */
window.o_global.pali.Tooltip.prototype._init = function () {
  this._parseDataAttributes();
  this._initTooltipEvents();
  this.trigger.tooltipInstance = this;
};

/**
 *
 *
 *
 *
 *
 */
window.o_global.pali.Tooltip.prototype._parseDataAttributes = function () {
  let parsedAttribute = null;
  let attribute;

  for (const key in this.dataSettings) {
    if (this.dataSettings.hasOwnProperty(key)) {
      attribute = this.trigger.getAttribute(this.dataSettings[key]);

      if (attribute) {
        parsedAttribute = null;

        /*                              */
        switch (attribute) {
          case "false":
            parsedAttribute = false;
            break;
          case "true":
            parsedAttribute = true;
            break;
          default:
            parsedAttribute = attribute;
        }
        this[key] = parsedAttribute;
      }
    }
  }
};

/**
 *
 *
 *
 */
window.o_global.pali.Tooltip.prototype._initTooltipEvents = function () {
  if (this.triggerClick) {
    this.trigger.addEventListener("click", this.toggleTooltip.bind(this));
  } else if (window.o_global.device.isTouchable) {
    if (this.showForTouch) {
      this._initTooltipTouchEvents();
    }

    if (window.o_global.device.hybrid.isDetected) {
      this._initTooltipMouseEvents();
    }
  } else {
    this._initTooltipMouseEvents();
  }
};

/**
 *
 *
 *
 */
window.o_global.pali.Tooltip.prototype._initTooltipTouchEvents = function () {
  const that = this;

  if ("onpointerdown" in window && !("ontouchstart" in window)) {
    if (window.o_global.device.hybrid.isDetected) {
      this.trigger.addEventListener("pointerdown", function () {
        that.avoidCloseTooltip = !that.avoidCloseTooltip;
      });
    } else {
      this.trigger.addEventListener("pointerdown", this.toggleTooltip.bind(this));
    }
  } else {
    this.trigger.addEventListener("touchstart", this.toggleTooltip.bind(this));
  }
};

/**
 *
 *
 *
 */
window.o_global.pali.Tooltip.prototype._initTooltipMouseEvents = function () {
  this.trigger.addEventListener("mouseenter", this.showTooltip.bind(this));
  this.trigger.addEventListener("mouseleave", this.hideTooltip.bind(this));

  if (this.position === "mouse") {
    this.mouseOffset = 20;
    this.trigger.addEventListener(
      "mousemove",
      this._throttle(this.moveTooltip, this.throttleTime).bind(this),
    );
  }
};

/**
 *
 *
 *
 *
 */
window.o_global.pali.Tooltip.prototype._createTooltip = function () {
  let el;

  this.tooltip = document.createElement("div");
  this.tooltip.content = document.createElement("div");

  /*                       */
  if (window.o_global.device.isTouchable || this.triggerClick) {
    if (this.hasCloseButton) {
      this._initCloseButton();
    }
  }

  /*          */
  if (this.reference) {
    if (this.referenceToClone) {
      el = document.getElementById(this.reference).cloneNode(true);
    } else {
      el = document.getElementById(this.reference);
    }

    el.removeAttribute("id"); /*                                       */
    el.removeAttribute("style"); /*          */
    this.tooltip.content.appendChild(el);
  } else {
    this.tooltip.content.innerHTML = this.text ? this.text : ""; /*                                   */
  }

  this.tooltip.appendChild(this.tooltip.content);

  /*          */
  this.addTooltipStyleClass(this.tooltip, this.baseClass);
  if (this.type) {
    this.addTooltipStyleClass(this.tooltip, `${this.baseClass}--${this.type}`);
  }
  this.addTooltipStyleClass(this.tooltip, this.orientationVariations[this.orientation]);

  if (this.additionalContainerClasses) {
    this.addTooltipStyleClass(this.tooltip, this.additionalContainerClasses);
  }

  this.addTooltipStyleClass(this.tooltip.content, "p_tooltip__content");

  if (this.additionalStyleClasses) {
    this.addTooltipStyleClass(this.tooltip.content, this.additionalStyleClasses);
  }

  if (this.position.match(/^(-?\d+);(-?\d+)$/)) {
    this.tooltipX = parseInt(RegExp.$1, 10);
    this.tooltipY = parseInt(RegExp.$2, 10);
  }

  if ((window.o_global.device.isTouchable && this.showForTouch) || this.triggerClick) {
    if ("onpointerdown" in window && !("ontouchstart" in window)) {
      this.tooltip.addEventListener("pointerdown", function (e) {
        e.stopPropagation();
      });
    } else {
      this.tooltip.addEventListener("touchstart", function (e) {
        e.stopPropagation();
      });
    }
  }
};

/**
 *
 *
 *
 */
window.o_global.pali.Tooltip.prototype._initCloseButton = function () {
  this.tooltip.close = document.createElement("a");
  this.tooltip.close.classList.add("p_tooltip__close");
  this.tooltip.close.addEventListener("click", this.toggleTooltip.bind(this));

  this.tooltip.appendChild(this.tooltip.close);
};

/**
 *
 *
 *
 *
 *
 */
window.o_global.pali.Tooltip.prototype._getPixel = function (val) {
  return `${parseInt(val, 10)}px`;
};

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
window.o_global.pali.Tooltip.prototype._throttle = function (fn, threshold, scope) {
  let last;
  let deferTimer;
  const timer = threshold || 250;

  return function () {
    const context = scope || this;
    const now = new Date();
    const args = arguments;

    if (last && now < last + timer) {
      /*            */
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function () {
        last = now;
        fn.apply(context, args);
      }, timer);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
};

/**
 *
 */
window.o_global.pali.tooltipHandler = (function () {
  let activeTooltip;
  const triggerClass = "js_hasPaliTooltip";
  const supportsOrientationChange = "onorientationchange" in window;
  const orientationEvent = supportsOrientationChange ? "orientationchange" : "resize";

  window.o_global.eventLoader.onReady(80, function () {
    if (window.o_global.device.isTouchable) {
      if ("onpointerdown" in window && !("ontouchstart" in window)) {
        document.body.addEventListener("pointerdown", _buildTooltip);
      } else {
        document.body.addEventListener("touchstart", _buildTooltip);
      }

      window.addEventListener(orientationEvent, blurTooltip);

      /*                                                                      */
      window.o_global.device.hybrid.executeOrRegisterCallback(updateHybrid);
    } else {
      document.body.addEventListener("click", _buildTooltip);
      document.body.addEventListener("mouseover", _buildTooltip);
    }
  });

  /**
 *
 *
 *
 *
 *
 */
  function _buildTooltip(e) {
    const eventTarget = e.target || event.srcElement;
    let tooltip = null;
    let triggerElement;

    triggerElement = window.o_util.dom.getParentByClassName(eventTarget, triggerClass);

    if (!triggerElement) {
      return;
    }

    if (triggerElement.tooltipInstance) {
      if (
        (triggerElement.tooltipInstance.triggerClick && e.type === "click") ||
        (!triggerElement.tooltipInstance.triggerClick && e.type !== "click")
      ) {
        triggerElement.tooltipInstance.activateTooltip(e); /*                                    */
      }
    } else {
      tooltip = new window.o_global.pali.Tooltip(triggerElement); /*                                                            */

      if (
        (tooltip.triggerClick && e.type === "click") ||
        (!tooltip.triggerClick && e.type !== "click")
      ) {
        tooltip.activateTooltip(e); /*                                    */
      }
    }

    if (!!triggerElement.tooltipInstance.getShowForTouch() && e.type === "click") {
      window.o_util.event.stop(e);
    }
  }

  /**
 *
 *
 *
 */
  function blurTooltip(e) {
    if (!!activeTooltip && activeTooltip.tooltipIsVisible) {
      if (
        !("onpointerdown" in window) ||
        ("onpointerdown" in window && e.target !== activeTooltip.trigger)
      ) {
        activeTooltip.avoidCloseTooltip = false;
        activeTooltip.hideTooltip();
      }
    }
  }

  /**
 *
 *
 *
 */
  function changeActiveTooltip(tooltip) {
    if (!!activeTooltip && activeTooltip !== tooltip) {
      activeTooltip.avoidCloseTooltip = false;
      activeTooltip.hideTooltip();
    }

    activeTooltip = tooltip;
  }

  /**
 *
 *
 *
 */
  function removeTooltip(domArr) {
    for (let i = domArr.length - 1; i >= 0; i--) {
      if (domArr[i].tooltipInstance) {
        domArr[i].tooltipInstance.deactivateTooltip();
      } else if (domArr[i]) {
        domArr[i].classList.remove("js_hasPaliTooltip");
      }
    }
  }

  /**
 *
 */
  function resetTooltipClasses() {
    const initializedElements = document.getElementsByClassName("js_hasPaliTooltip--done");

    for (let i = initializedElements.length - 1; i >= 0; i--) {
      initializedElements[i].classList.add("js_hasPaliTooltip");
      initializedElements[i].classList.remove("js_hasPaliTooltip--done");
    }
  }

  /**
 *
 *
 *
 */
  function updateHybrid() {
    document.body.addEventListener("mouseover", _buildTooltip);
  }

  return {
    blurTooltip,
    changeActiveTooltip,
    removeTooltip,
    resetTooltipClasses,
    updateHybrid,
  };
})();
